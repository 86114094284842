/// Function to check if a string could be a valid URL
export function checkUrl(url: string): string | boolean {
  // Regular expression to check if the string has a valid URL format

  const urlRegex =
    /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+)(:\d+)?(\/[^\s]*)?(\?[^\s]*)?(#[^\s]*)?$/;

  //const urlRegex = /^(https?:\/\/)?(www\.)?([a-zA-Z0-9-]+(\.[a-zA-Z]{2,})+)(\/[^\s]*)?$/;

  // Check if the string matches the URL regex
  if (urlRegex.test(url)) {
    // If the scheme (http:// or https://) is missing, add it
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = "https://" + url;
    }

    // Now the URL is modified and can be considered valid
    return url;
  }

  // If the string doesn't match the URL format, it's not valid
  return false;
}
